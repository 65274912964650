<!--<button class="btn btn-icon" (click)="toggle()" [disabled]="isDisabled">-->
<!--    {{btnLabel}}-->
<!--    <span [inlineSVG]="btnIcon"></span>-->
<!--</button>-->

<div class="d-flex  m-0 w-100 options-menu" >
    <div class="p-1" *ngFor="let option of options">
        <div [ngSwitch]="option?.actionType">
            <div *ngSwitchCase="'view'" [ngbTooltip]="'View'">
                <button class="btn btn-secondary btn-sm px-2 " (click)="fireAction(option)">
                    <i class=" p-0 fa fa-eye"></i>
                </button>
            </div>
            <div *ngSwitchCase="'edit'" [ngbTooltip]="'Edit'">
                <button class="btn btn-secondary btn-sm px-2 " (click)="fireAction(option)">
                    <i class="p-0 fa fa-pencil"></i>
                </button>
            </div>

            <div *ngSwitchCase="'duplicate'" [ngbTooltip]="'Duplicate'">
                <button class="btn btn-secondary btn-sm px-2 " (click)="fireAction(option)">
                    <i class="p-0 fa fa-copy"></i>
                </button>
            </div>
            <div *ngSwitchCase="'info'" [ngbTooltip]="'Details'">
                <button class="btn btn-secondary btn-sm px-2 " (click)="fireAction(option)">
                    <i class="p-0 fa fa-info-circle"></i>
                </button>
            </div>
            <div *ngSwitchCase="'complete'" [ngbTooltip]="'Complete'">
                <button class="btn btn-secondary  btn-sm px-2 align-items-center d-flex" (click)="fireAction(option)">
                    <i class=" p-0 fa fa-check-circle  me-2"></i><span class="fs-5"><b>Complete</b></span>
                </button>
            </div>
            <div *ngSwitchCase="'remove'" [ngbTooltip]="'Remove'">
                <button class="btn btn-secondary btn-sm px-2 " (click)="fireAction(option)">
                    <i class="p-0 fa text-white fa-trash"></i>
                </button>
            </div>
            <div *ngSwitchCase="'tracking'" [ngbTooltip]="'Tracking'">
                <button class="btn btn-secondary btn-sm px-2 " (click)="fireAction(option)">
                    <i class=" p-0 fa fa-square-arrow-up-right"></i>
                </button>
            </div>
        </div>
    </div>

</div>

<div class="options-menu__options" #menuOptions>
    <div mat-menu-item class="menu-item" *ngFor="let option of options">
        <a (click)="fireAction(option)" class="menu-link">
            <span class="menu-icon">
                <i class="bi fs-3" [ngClass]="getClass(option.icon)"></i>
            </span>
            <span class="menu-title">{{ option.label | translate }}</span>
        </a>
    </div>
</div>