import { Component } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

export interface StelvioModalButtonModel {
  label: string;
  classes: string;
  action: "close" | "dismiss" | "ok";
}

export interface StelvioModalModel {
  type: "error" | "warning" | "success" | "info";
  title: string;
  description?: string;
  descriptionHTML?: string;
  component?: Component;
  buttons: StelvioModalButtonModel[];
}

@Component({
  selector: "stelvio-modal",
  templateUrl: "./stelvio-modal.component.html",
  styleUrls: ["./stelvio-modal.component.scss"],
})
export class StelvioModalComponent {
  settings: StelvioModalModel;
  ref: NgbModalRef;

  constructor(private modalService: NgbModal) {

    console.log(this.settings)
  }

  fireAction(action: string, reason?: any) {
    switch (action) {
      case "close":
        this.ref.close(reason);
        break;
      case "dismiss":
        this.modalService.dismissAll(reason);
        break;
    }
  }
}
