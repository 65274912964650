import { Injectable } from "@angular/core";
import { FacilitiesRequest } from "../requests/facilities.request";
import { FacilityModel } from "../models";
import { PaginationModel } from "../models/pagination.model";
import { DefaultPaginationSettings } from "../config/default-pagination.settings";
import { map } from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class FacilitiesFacade {
  private defaultPic: string = '../../../../assets/media/avatars/company-generic.png';

  constructor(private request: FacilitiesRequest) {}

  query(pagination: PaginationModel = DefaultPaginationSettings, filters?: any): Observable<any> {
    return this.request.query(pagination, filters).pipe(
        map( (response: any) => {
          return {
            ...response,
            content: response.map( (product: any) => this._buildPicturePath(product))
          };
        })
    );
  }

  get(id: string) {
    return this.request.get(id).pipe(
        map( (facility: any) => this._buildPicturePath(facility))
    );
  }

  update(facility: FacilityModel) {
    return this.request.update(facility);
  }

  create(facility: FacilityModel) {
    return this.request.create(facility);
  }

  uploadPic(image: any, facilityId: number, companyId: number | string) {
    return this.request.uploadPic(image, facilityId, companyId);
  }

  delete(id: string | number) {
    return this.request.remove(id);
  }

  private _buildPicturePath(facility: any) {
    const imagePath: string = !!facility?.image?.path ? `/images/${facility.company.id}/${facility.image.path}` : this.defaultPic;
    let companyImagePath: string = this.defaultPic
    if(facility?.company?.logo?.path){
      const companyLogo = facility?.company?.logo?.path
      companyImagePath = `/images/${facility.company.id}/${companyLogo}`;
    }
    return {
      ...facility,
      badgeType: facility?.approval?.status.toLowerCase(),
      imagePath,
      companyImagePath
    }
  }

}
