import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {StelvioGridSettingsColumnOptionsModel} from "../stelvio-grid-settings.model";

@Component({
    selector: 'options-menu',
    templateUrl: './options-menu.component.html',
    styleUrls: ['./options-menu.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class OptionsMenuComponent {
    @Input() options: StelvioGridSettingsColumnOptionsModel[];
    @Input() btnLabel: string;
    @Input() btnIcon: string;
    @Input('disabled') isDisabled: boolean;
    @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('menuOptions') menuOptions: ElementRef;

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if(!this.eRef.nativeElement.contains(event.target)) {
            this.eRef.nativeElement.querySelector('.options-menu__options').classList.remove('open');
        }
    }


    constructor(private eRef: ElementRef) {
    }

    fireAction(option: StelvioGridSettingsColumnOptionsModel) {
        if(option.action) {
            this.onAction.emit(option);
        } else {
            window.open(option.link);
        }
    }

    getClass(c: any) {
        return {
            [c]: true
        };
    }

    toggle() {
        this.menuOptions.nativeElement.classList.toggle('open');
    }
}