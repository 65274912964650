import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ActivityShortModel} from "../models";
import {of} from "rxjs";
import {PaginationModel} from "../models/pagination.model";

@Injectable()
export class ActivitiesRequest {
    private apiRoot: string = `${environment.apiUrl}/workflow/activity/`;
    constructor(private http: HttpClient) {
    }

    query(pagination: PaginationModel,filters?: any) {
        return this.http.get(`${this.apiRoot}search/`, {
            params: {
                size: pagination.pageSize,
                page: pagination.pageNumber,
                ...filters
            }
        });
    }

    queryWithData(filters?: any) {
        return this.http.get(`${this.apiRoot}search/pendingData/`, {});
    }

    assignUser(workflowId: number, workflowStepId: number, companyId: number, userIds: number[]) {
        return this.http.post(`${environment.apiUrl}/workflow/${workflowId}/step/${workflowStepId}/users`, {
            companyId,
            workflowStepId,
            userIds
        })
    }

    removeUser(workflowId: number, workflowStepId: number, companyId: number, userIds: number[]) {
        return this.http.delete(`${environment.apiUrl}/workflow/${workflowId}/step/${workflowStepId}/users/`, {
            body: {
                companyId,
                workflowStepId,
                userIds
            },
        })
    }

    getStepUsers(workflowId: number, workflowStepId: number) {
        return this.http.get(`${environment.apiUrl}/workflow/${workflowId}/step/${workflowStepId}/users/`);
    }

    getUserSteps(userId: number) {
        return this.http.get(`${environment.apiUrl}/workflow/step/user/${userId}/`);
    }

    get(id: number) {
        return this.http.get(`${this.apiRoot}${id}`);
    }

    create(activity: ActivityShortModel) {
        return this.http.post(`${this.apiRoot}`, activity);
    }

    update(activity: ActivityShortModel) {
        return of({});
        return this.http.put(`${this.apiRoot}${activity.id}/`, activity);
    }

    delete(id: number) {
        return this.http.delete(`${this.apiRoot}${id}/`);
    }

    uploadImage(image: any) {
        const formData: FormData = new FormData();
        formData.set('image', image, image.name);
        return this.http.post(`${this.apiRoot}image/`, formData);
    }

    uploadDoc(doc: any) {
        const formData: FormData = new FormData();
        formData.set('document', doc, doc.name);
        return this.http.post(`${this.apiRoot}document/`, formData);
    }

    existsFinalBatchOut(batchId: string, productId: number) {
        return this.http.get(`${this.apiRoot}batchId/${batchId}/productId/${productId}`);
    }
}
