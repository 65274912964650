import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {FacilityModel} from "../models";
import {PaginationModel} from "../models/pagination.model";

@Injectable()
export class FacilitiesRequest {
    private apiRoot: string = `${environment.apiUrl}/facility`;

    constructor(private http: HttpClient) {
    }

    query(pagination: PaginationModel, filters?: any) {
        if(!!filters){
            const keys = Object.keys(filters);
            keys.map( (key: string) => {
                if(filters[key] === undefined) {
                    delete filters[key];
                }
            });
        }
        return this.http.get(`${this.apiRoot}/all`,{
            params: {
                size: pagination.pageSize,
                page: pagination.pageNumber,
                ...filters
            }
        });
    }

    get(id: string) {
        return this.http.get(`${this.apiRoot}/${id}/`);
    }

    update(facility: FacilityModel) {
        return this.http.put(`${this.apiRoot}`, facility);
    }

    create(facility: FacilityModel) {
        return this.http.post(`${this.apiRoot}`, facility);
    }

    remove(id: string | number) {
        return this.http.delete(`${this.apiRoot}${id}/`);
    }

    uploadPic(image: any, facilityId: number, companyId: number | string) {
        const formData: FormData = new FormData();
        formData.set('image', image, image.name);
        formData.set('idFacility', facilityId+'');
        formData.set('idCompany', companyId+'');
        return this.http.post(`${this.apiRoot}/image/`, formData)
    }

}
