import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef
} from "@angular/core";
import {StelvioGridSettingsModel} from "./stelvio-grid-settings.model";
import {Observable} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: "stelvio-grid",
    templateUrl: "./stelvio-grid.component.html",
    styleUrls: [
        "./stelvio-grid.component.scss",
        "../../../../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css",
    ],
})
export class StelvioGridComponent implements OnInit, OnChanges {
    @Input() settings: StelvioGridSettingsModel;
    currentSettings: any;
    @Input() data: Observable<any[]>;
    @Input() message_no_data:string = "";
    @Output() onNew: EventEmitter<any> = new EventEmitter();
    @Output() onPage: EventEmitter<{ pageNumber: number }> = new EventEmitter<{
        pageNumber: number;
    }>();
    @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSort: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDuplicate: EventEmitter<any> = new EventEmitter<any>();
    fg: FormGroup = new FormGroup({
        search: new FormControl("", [Validators.required]),
        active: new FormControl("enabled", []),
    });
    pages: number[] = [];

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.cdRef.detectChanges();
        this.currentSettings = changes.settings
    }

    ngOnInit() {
        this.currentSettings = this.settings

        console.log("this.message_no_data")
        console.log(this.message_no_data)
        if (!this.settings.filters?.filterByActive)
            this.fg.controls.active.setValue(null);

        if (this.settings.filters?.defaultValues) {
            const active = !!this.settings.filters.defaultValues?.active
                ? "enabled"
                : this.settings.filters.defaultValues?.active === false
                    ? "disabled"
                    : null;
            this.fg.controls.active.setValue(active);
            this.fg.controls.search.setValue(
                this.settings.filters.defaultValues.search
            );
        }

        for (let i = 0; i < this.settings.pagination.totalPages; i++) {
            this.pages.push(i);
        }

    }

    fireAction(event: { id: number; option: any; item: any }) {
        this.onAction.emit(event);
    }

    fireNewEvent() {
        this.onNew.emit(true);
    }

    firePage(page: number) {
        this.onPage.emit({pageNumber: page});
    }

    fireSort(params: { field: string; order: string }) {
        if (!params.field) params.field = "id";
        if (!params.order) params.order = "desc";
        this.onSort.emit({sort: `${params.field}-${params.order}`});
    }

    fireSearch() {
        const params = this.fg.getRawValue();
        if (!this.settings.filters?.filterByActive) params.active = undefined;
        else params.active = params.active == "enabled";

        this.onSearch.emit(params);
    }
}
