<div class="grid-wrapper">
    <div class="filters-wrapper">
        <div class="filters-wrapper__title col-4">{{(data | async)?.length}} {{settings.labels.title | translate}}</div>
        <div class="col-5">
            <form [formGroup]="fg" (ngSubmit)="fireSearch()" class="filters-wrapper__search-wrapper" *ngIf="settings?.filters?.enableFilters">
                <div class="filters-wrapper__search">
                    <button class="search-form-submit-btn" type="submit"></button>
                    <input type="text" [tabIndex]="3" formControlName="search" tabindex="1" placeholder="Search" />
                </div>
                <div>
                    <mat-button-toggle-group tabindex="2" formControlName="active" *ngIf="settings?.filters?.filterByActive">
                        <mat-button-toggle value="enabled" *ngIf="settings?.filters?.activeFilterLabels?.enabledLabel">{{ (settings?.filters?.activeFilterLabels?.enabledLabel || '') | translate}}</mat-button-toggle>
                        <mat-button-toggle value="disabled" *ngIf="settings?.filters?.activeFilterLabels?.disabledLabel">{{ (settings?.filters?.activeFilterLabels?.disabledLabel || '') | translate}}</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </form>
        </div>
        <div class="filters-wrapper__new" *ngIf="!!settings.labels.newBtn">
            <button class="btn btn-outline-secondary" (click)="fireNewEvent()" *ngIf="settings.canCreateNew">
                <i class="bi bi-plus text-white" style="font-size: 2rem"></i>{{settings.labels.newBtn | translate}}
            </button>
        </div>
    </div>
    <stelvio-grid-body [settings]="settings" [message_no_data]="message_no_data" [data]="data" (onAction)="fireAction($event)" (onSort)="fireSort($event)"></stelvio-grid-body>

    <div class="grid-pagination">
        <ul class="pagination" *ngIf="settings.pagination.totalPages > 1">
            <li class="page-item previous" [ngClass]="{'disabled': settings.pagination.pageNumber == 0}"><a (click)="firePage(settings.pagination.pageNumber - 1)" class="page-link"><i class="previous"></i></a></li>
            <li class="page-item"
                [ngClass]="{'active': page === settings.pagination.pageNumber}"
                *ngFor="let page of pages">
                <a (click)="firePage(page)" class="page-link">{{page+1}}</a>
            </li>
            <li class="page-item next"><a (click)="firePage(settings.pagination.pageNumber + 1)"  class="page-link"><i class="next"></i></a></li>
        </ul>
    </div>
</div>