import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {MemberModel} from "../models";
import {PaginationModel} from "../../common/models/pagination.model";

@Injectable()
export class MembersRequest {
    private apiRoot: string = `${environment.apiUrl}/user`;

    constructor(private http: HttpClient) {
    }

    query(pagination: PaginationModel, params?: any): Observable<any>  {
        return this.http.get(`${this.apiRoot}/`, {
            params: {
                size: pagination.pageSize,
                page: pagination.pageNumber,
                ...params,
            }
        });
    }

    get(memberId: string) {
        return this.http.get(`${this.apiRoot}/${memberId}/`);
    }

    create(member: MemberModel) {
        return this.http.post(`${this.apiRoot}`, member);
    }

    update(member: MemberModel) {
        return this.http.put(`${this.apiRoot}`, member);
    }

    delete(memberId: number) {
        return this.http.delete(`${this.apiRoot}/${memberId}/`);
    }

    uploadProfilePic(image: any, idMember: number, idCompany: number) {
        const formData: FormData = new FormData();
        formData.set('image', image, image.name);
        formData.set('idUser', idMember+'');
        formData.set('idCompany', idCompany+'');
        return this.http.post(`${this.apiRoot}/avatar/`, formData);
    }

    dashboard(id: number) {
        return this.http.get(`${this.apiRoot}/${id}/dashboard/`)
    }
}